import { useApolloClient } from "@apollo/client";

export const useRefetchShipments = () => {
  const client = useApolloClient();

  return {
    refetchAlertCounts: async () => {
      client.refetchQueries({ include: ["AlertCounts"] });
    },

    refetchAll: async () => {
      client.refetchQueries({ include: ["Shipments", "AlertCounts"] });
    },

    refetchShipments: async () => {
      client.refetchQueries({ include: ["Shipments"] });
    },
  };
};
