import {
  Box,
  BoxProps,
  Center,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, formatISO } from "date-fns";
import uniq from "lodash/uniq";
import { useRouter } from "next/router";
import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { Overlay } from "~/components/overlay/Overlay";

type Props = {
  name: string;
  options?: Array<{ label: string; value: string }>;
  type: "input" | "select" | "date" | "datetime";
};

export const Searchable: React.VFC<Props & BoxProps> = ({ type, name, options, ...props }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();

  const [value, setValue] = React.useState("");
  const [exclude, setExclude] = React.useState(false);

  const [dateType, setDateType] = React.useState("range");
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const initialRef = React.useRef<HTMLInputElement | null>(null);

  const label = t(`model.shipment.${name}` as any);

  const query = router.query;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onClose();

    let queryValue;
    if (["input", "select"].includes(type)) {
      queryValue = value;
    } else if (["date", "datetime"].includes(type)) {
      if (dateType === "range") {
        if (startDate || endDate) {
          queryValue = [startDate ? formatISO(startDate) : "", endDate ? formatISO(endDate) : ""].join("_");
        }
      } else {
        queryValue = dateType;
      }
    }

    setValue("");
    setExclude(false);
    setStartDate(null);
    setEndDate(null);
    setDateType("range");

    if (!queryValue) return;

    if (type === "select" && exclude) queryValue = `-${queryValue}`;

    let q = query[name];
    if (typeof q === "string") q = [q];
    if (typeof q === "undefined") q = [];

    router.push(
      {
        query: {
          ...query,
          [name]: uniq([...q, queryValue]),
          page: 1,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <Box {...props}>
      <Tooltip label="検索" placement="top">
        <ChakraButton colorScheme={"blue"} onClick={onOpen} size={"xs"} variant="ghost">
          <FontAwesomeIcon color="blue" icon={faFilter} />
        </ChakraButton>
      </Tooltip>

      <Modal
        initialFocusRef={initialRef}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>
              検索条件
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <FormControl>
                <FormLabel>{label}</FormLabel>

                {type === "input" && (
                  <Input onChange={(e) => setValue(e.target.value)} placeholder={label} ref={initialRef} />
                )}

                {type === "select" && (
                  <Flex gap={4}>
                    <Select onChange={(e) => setValue(e.target.value)}>
                      <option value="">---</option>
                      {options?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    <Checkbox isChecked={exclude} onChange={(e) => setExclude(e.target.checked)} whiteSpace="nowrap">
                      除外する
                    </Checkbox>
                  </Flex>
                )}

                {["date", "datetime"].includes(type) && (
                  <RadioGroup mt={4} onChange={setDateType} value={dateType}>
                    <Stack spacing={6}>
                      <Box>
                        <Radio value="range">日時指定</Radio>

                        <Overlay isLoading={dateType !== "range"} noSpinner>
                          <Flex direction={{ base: "column", sm: "row" }} gap={2} mt={2}>
                            <Box>
                              <InputGroup mb={2}>
                                <Input
                                  placeholder="開始日時"
                                  readOnly
                                  value={
                                    startDate
                                      ? format(startDate, type === "date" ? "yyyy/MM/dd" : "yyyy/MM/dd HH:mm")
                                      : ""
                                  }
                                />
                                {startDate && (
                                  <InputRightElement>
                                    <IconButton
                                      aria-label=""
                                      isRound
                                      onClick={() => setStartDate(null)}
                                      variant="ghost"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </IconButton>
                                  </InputRightElement>
                                )}
                              </InputGroup>

                              <DatePicker
                                inline
                                maxDate={new Date()}
                                minDate={new Date("2021-01-01")}
                                onChange={(date) => setStartDate(date)}
                                selected={startDate}
                                showMonthYearDropdown
                                showTimeSelect={type === "datetime"}
                              />
                            </Box>

                            <Center h={11} px={4}>
                              〜
                            </Center>

                            <Box>
                              <InputGroup mb={2}>
                                <Input
                                  placeholder="終了日時"
                                  readOnly
                                  value={
                                    endDate
                                      ? format(endDate || 0, type === "date" ? "yyyy/MM/dd" : "yyyy/MM/dd HH:mm")
                                      : ""
                                  }
                                />
                                {endDate && (
                                  <InputRightElement>
                                    <IconButton aria-label="" isRound onClick={() => setEndDate(null)} variant="ghost">
                                      <FontAwesomeIcon icon={faTimes} />
                                    </IconButton>
                                  </InputRightElement>
                                )}
                              </InputGroup>

                              <DatePicker
                                inline
                                maxDate={new Date()}
                                minDate={new Date("2021-01-01")}
                                onChange={(date) => setEndDate(date)}
                                selected={endDate}
                                showMonthYearDropdown
                                showTimeSelect={type === "datetime"}
                              />
                            </Box>
                          </Flex>
                        </Overlay>
                      </Box>
                      <Radio value="null">データなし</Radio>
                      <Radio value="notNull">データあり</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ChakraButton colorScheme="brand" type="submit" w={60}>
                検索条件 設定
              </ChakraButton>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
