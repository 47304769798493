import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import map from "lodash/map";
import omit from "lodash/omit";
import { useRouter } from "next/router";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { Card } from "~/components/data-display/Card";
import { SearchCondition } from "~/components/page/shipments/SearchCondition";

type Props = {};

export const SearchConditions: React.VFC<Props & BoxProps> = ({ ...props }) => {
  const router = useRouter();

  const onClick = (name: string, value: string) => {
    let values = router.query[name];
    if (typeof values === "string") values = [values];

    values = values?.filter((v) => v !== value);
    const query = {
      ...router.query,
      [name]: values,
    };
    if (values?.length === 0) delete query[name];

    if (Object.keys(query).length > 0) {
      router.push({ query }, undefined, { shallow: true });
    } else {
      router.push(router.pathname, undefined, { shallow: true });
    }
  };

  const onClearClick = () => {
    router.push({ query: { order: router.query.order } }, undefined, { shallow: true });
  };

  const query = omit(router.query, ["order", "page", "per"]);

  if (Object.keys(query).length === 0) return null;

  return (
    <Card minH={11} {...props}>
      <Flex alignItems="center">
        <Box flexShrink={0} fontSize="sm" fontWeight="bold">
          検索条件:
        </Box>

        <Box flexGrow={1}>
          <Flex direction="row" flexWrap="wrap" mt={-4} pl={2}>
            {map(query, (values, name) => {
              if (typeof values === "string") values = [values];

              return values?.map((value) => (
                <SearchCondition key={value} ml={2} mt={4} name={name} onClick={onClick} value={value} />
              ));
            })}
          </Flex>
        </Box>

        <Box pl={4}>
          <ChakraButton leftIcon={<FontAwesomeIcon icon={faBackspace} />} onClick={onClearClick}>
            すべてクリア
          </ChakraButton>
        </Box>
      </Flex>
    </Card>
  );
};
