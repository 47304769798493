import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Thead,
  Tbody,
  Th,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
  Table,
  TableContainer,
} from "@chakra-ui/react";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

import { Shipment } from "~generated/graphql";

type Props = {
  shipment: Shipment;
};

export const TrackingStatus: React.VFC<Props & BoxProps> = ({ shipment, ...props }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderYamato = () => {
    return (
      <>
        <Thead>
          <Tr>
            <Th>荷物状況</Th>
            <Th>日時</Th>
            <Th>担当店名</Th>
            <Th>担当店コード</Th>
          </Tr>
        </Thead>
        <Tbody>
          {shipment.trackings.map((tracking: any, index: number) => (
            <Tr key={index}>
              <Td>{tracking.status}</Td>
              <Td>
                {t("format.datetime.default", {
                  datetime: new Date(tracking.trackedAt),
                })}
              </Td>
              <Td>{tracking.site}</Td>
              <Td>{tracking.siteCode}</Td>
            </Tr>
          ))}
        </Tbody>
      </>
    );
  };

  const renderSagawa = () => {
    return (
      <>
        <Thead>
          <Tr>
            <Th>荷物状況</Th>
            <Th>日時</Th>
            <Th>担当営業所</Th>
          </Tr>
        </Thead>
        <Tbody>
          {shipment.trackings.map((tracking: any, index: number) => (
            <Tr key={index}>
              <Td>{tracking.status}</Td>
              <Td>
                {t("format.datetime.default", {
                  datetime: new Date(tracking.trackedAt),
                })}
              </Td>
              <Td>{tracking.site}</Td>
            </Tr>
          ))}
        </Tbody>
      </>
    );
  };

  const renderYouPack = () => {
    return (
      <>
        <Thead>
          <Tr>
            <Th>状態発生日</Th>
            <Th>配送履歴</Th>
            <Th>詳細</Th>
            <Th>取扱局</Th>
          </Tr>
        </Thead>
        <Tbody>
          {shipment.trackings.map((tracking: any, index: number) => (
            <Tr key={index}>
              <Td>
                {t("format.datetime.default", {
                  datetime: new Date(tracking.trackedAt),
                })}
              </Td>
              <Td>{tracking.status}</Td>
              <Td>{tracking.detail}</Td>
              <Td>{tracking.site}</Td>
            </Tr>
          ))}
        </Tbody>
      </>
    );
  };

  if (!shipment.trackings) return <Box color="muted">---</Box>;

  const renderTrackings = () => {
    return (
      <>
        <Tooltip label="詳細情報を見る" placement="top">
          <IconButton aria-label="" isRound onClick={onOpen}>
            <FontAwesomeIcon icon={faTruck} />
          </IconButton>
        </Tooltip>

        <Modal isCentered isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>詳細情報</ModalHeader>
            <ModalCloseButton />
            <TableContainer>
              <Table size="sm">
                {shipment.type === "Yamato" && renderYamato()}
                {shipment.type === "Sagawa" && renderSagawa()}
                {shipment.type === "YouPack" && renderYouPack()}
              </Table>
            </TableContainer>
            {!shipment.trackings ||
              (shipment.trackings.length === 0 && (
                <Box color="muted" my={4} textAlign="center">
                  データがありません
                </Box>
              ))}
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      <Box pr={8}>
        <Box>{shipment.latestTrackingStatus}</Box>

        {shipment.latestTrackedAt && (
          <Box fontSize="xs">
            {t("format.datetime.default", {
              datetime: new Date(shipment.latestTrackedAt),
            })}
          </Box>
        )}

        {shipment.fetchedAt && (
          <Box fontSize="xs" mt={2}>
            (取得日時:
            {t("format.datetime.default", {
              datetime: new Date(shipment.fetchedAt),
            })}
            )
          </Box>
        )}
      </Box>
      <Box>{renderTrackings()}</Box>
    </Flex>
  );
};
