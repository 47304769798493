import { MenuItemProps, useToast } from "@chakra-ui/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import { BaseMenuItem } from "~/components/page/shipments/menu-item/BaseMenuItem";
import { useDestroyShipmentMutation } from "~generated/graphql";

type Props = {
  ids: number[];
  resetChecked: () => void;
};

export const DeleteMenuItem: React.VFC<Props & MenuItemProps> = ({ ids, ...props }) => {
  const [mutation] = useDestroyShipmentMutation();
  const toast = useToast();

  const onMenuClick = async () => {
    await mutation({ variables: { input: { ids } } });
    toast({ position: "top", status: "success", title: "削除しました" });
  };

  return (
    <BaseMenuItem confirmMessage="削除してもよろしいですか？" menuIcon={faTrash} onMenuClick={onMenuClick} {...props}>
      削除
    </BaseMenuItem>
  );
};
