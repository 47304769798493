import React from "react";

import { ShipmentFragment } from "~generated/graphql";
import { generalNames } from "~hooks/useGeneralLabels";
import { useMe } from "~hooks/useMe";

export type ColumnOption = {
  alias?: string | null;
  enabled: boolean;
  id: keyof ShipmentFragment;
};

type Context = {
  columnOptions: ColumnOption[];
  setColumnOptions: React.Dispatch<React.SetStateAction<ColumnOption[]>>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const Context = React.createContext<Context>({ columnOptions: [], setColumnOptions: () => {} });

const defaultColumnOptions: ColumnOption[] = [
  {
    enabled: true,
    id: "id",
  },
  {
    enabled: true,
    id: "type",
  },
  {
    enabled: true,
    id: "trackingNumber",
  },
  {
    enabled: true,
    id: "status",
  },
  {
    enabled: true,
    id: "latestTrackingStatus",
  },
  {
    enabled: true,
    id: "shippedAt",
  },
  {
    enabled: true,
    id: "shippingAlertOn",
  },
  {
    enabled: true,
    id: "deliveredAt",
  },
  {
    enabled: true,
    id: "deliveryAlertOn",
  },
  {
    enabled: true,
    id: "createdAt",
  },
  ...generalNames.map((name) => ({
    enabled: true,
    id: name,
  })),
];

export const ShipmentTableContext: React.FC = (props) => {
  const { user } = useMe();

  const [columnOptions, setColumnOptions] = React.useState<ColumnOption[]>(
    user?.group.columnOptions || defaultColumnOptions
  );

  React.useEffect(() => {
    if (!user?.group.columnOptions) return;

    setColumnOptions(user.group.columnOptions);
  }, [user?.group?.columnOptions]);

  return <Context.Provider value={{ columnOptions, setColumnOptions }}>{props.children}</Context.Provider>;
};

export const useShipmentTableColumns = () => {
  return React.useContext(Context);
};
