import { ButtonProps } from "@chakra-ui/react";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { useRefetchShipments } from "~hooks/useRefetchShipments";

type Props = {};

export const ReloadButton: React.VFC<Props & ButtonProps> = ({ ...props }) => {
  const { refetchAll } = useRefetchShipments();

  return (
    <ChakraButton leftIcon={<FontAwesomeIcon icon={faRedo} />} onClick={() => refetchAll()} {...props}>
      リロード
    </ChakraButton>
  );
};
