import { BoxProps, Center, chakra } from "@chakra-ui/react";
import React from "react";

import { ShipmentTypeEnum } from "~generated/graphql";

type Props = {
  type: ShipmentTypeEnum;
};

const srcs = {
  Hukuyama: "hukuyama.png",
  Sagawa: "sagawa.png",
  Seino: "seino.jpg",
  Yamato: "yamato.svg",
  YouPack: "you-pack.png",
};

export const ShippingCarrierLogo: React.VFC<Props & BoxProps> = ({ type, ...props }) => {
  const src = srcs[type];

  if (!src) return null;

  return (
    <Center display="inline-flex" h={8} textAlign="center" w={20}>
      <chakra.img maxH="full" maxW="full" {...props} src={src} />
    </Center>
  );
};
