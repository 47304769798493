import { BoxProps, IconButton, Tooltip } from "@chakra-ui/react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Shipment, useFetchTrackingsCsvMutation } from "~generated/graphql";
import { useRefetchShipments } from "~hooks/useRefetchShipments";

type Props = {
  shipment: Shipment;
};

export const FetchTrackingsButton: React.VFC<Props & BoxProps> = ({ shipment, ...props }) => {
  const [mutation, { loading }] = useFetchTrackingsCsvMutation();
  const { refetchAlertCounts } = useRefetchShipments();

  const onClick = async (ids: number[]) => {
    await mutation({ variables: { input: { ids } } });
    refetchAlertCounts();
  };

  return (
    <Tooltip label="配送ステータスを取得する" placement="top" {...props}>
      <IconButton aria-label="" isLoading={loading} isRound onClick={() => onClick([shipment.id])}>
        <FontAwesomeIcon icon={faSync} />
      </IconButton>
    </Tooltip>
  );
};
