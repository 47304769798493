import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  Tooltip,
  chakra,
} from "@chakra-ui/react";
import { faExclamationCircle, faExclamationTriangle, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { find, range } from "lodash";
import React from "react";

import { NextLink } from "~/components/alias/NextLink";
import { ColumnAliasable } from "~/components/page/shipments/ColumnAliasable";
import { DeleteShipmentButton } from "~/components/page/shipments/DeleteShipmentButton";
import { FetchTrackingsButton } from "~/components/page/shipments/FetchTrackingsButton";
import { Searchable } from "~/components/page/shipments/Searchable";
import { ShipmentStatus } from "~/components/page/shipments/ShipmentStatus";
import { useShipmentTableColumns } from "~/components/page/shipments/ShipmentTableContext";
import { ShippingCarrierLogo } from "~/components/page/shipments/ShippingCarrierLogo";
import { Sortable } from "~/components/page/shipments/Sortable";
import { TrackingNumber } from "~/components/page/shipments/TrackingNumber";
import { TrackingStatus } from "~/components/page/shipments/TrackingStatus";
import { Card } from "~data-display/Card";
import { ReadMore } from "~data-display/ReadMore";
import { pagesPath } from "~generated/$path";
import { Shipment, ShipmentFragment } from "~generated/graphql";
import { generalNames } from "~hooks/useGeneralLabels";

type Props = {
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  shipments: Shipment[] | null | undefined;
};

const CustomTh: React.FC<BoxProps> = ({ children, ...props }) => {
  const { w, ...rest } = props;

  return (
    <Th w={w}>
      <Flex alignItems="center" justifyContent="space-between" {...rest}>
        {children}
      </Flex>
    </Th>
  );
};

type Column = {
  id: keyof ShipmentFragment;
  render?: (shipment: ShipmentFragment) => React.ReactNode;
  renderHeader: () => React.ReactNode;
  tdProps?: BoxProps;
  thProps?: BoxProps;
};

const renderDate = (value: string | null | undefined) => {
  if (!value) return "";
  return t("format.date.default", { date: new Date(value) });
};

const renderDateTime = (value: string | null | undefined) => {
  if (!value) return "";
  return t("format.datetime.default", { datetime: new Date(value) });
};

const Columns: Column[] = [
  {
    id: "id",
    renderHeader: () => (
      <>
        <Sortable name="id" />
        <Searchable name="id" type="input" />
      </>
    ),
    tdProps: { textAlign: "right" },
    thProps: { w: "24" },
  },
  {
    id: "type",
    render: (shipment) => <ShippingCarrierLogo type={shipment.type} />,
    renderHeader: () => (
      <>
        {t("model.shipment.type")}
        <Searchable name="type" options={t("model.shipment.typeOptions", { returnObjects: true })} type="select" />
      </>
    ),
    tdProps: { textAlign: "center" },
    thProps: { w: 40 },
  },
  {
    id: "trackingNumber",
    render: (shipment) => <TrackingNumber trackingNumber={shipment.trackingNumber} type={shipment.type} />,
    renderHeader: () => (
      <>
        {t("model.shipment.trackingNumber")}
        <Searchable name="trackingNumber" type="input" />
      </>
    ),
    thProps: { w: 40 },
  },
  {
    id: "status",
    render: (shipment) => <ShipmentStatus shipmentStatus={shipment.status} />,
    renderHeader: () => (
      <>
        <Sortable name="status" />
        <Searchable
          name="status"
          options={t("model.shipment.statusOptions", {
            returnObjects: true,
          })}
          type="select"
        />
      </>
    ),
    thProps: { w: 40 },
  },
  {
    id: "latestTrackingStatus",
    render: (shipment) => (
      <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
        <TrackingStatus flexGrow={1} shipment={shipment} />
        <FetchTrackingsButton shipment={shipment} />
      </Stack>
    ),
    renderHeader: () => (
      <>
        {t("model.shipment.latestTrackingStatus")}
        <Searchable name="latestTrackingStatus" type="input" />
      </>
    ),
    thProps: { w: "350px" },
  },
  {
    id: "shippedAt",
    render: (shipment) => renderDateTime(shipment.shippedAt),
    renderHeader: () => (
      <>
        <Sortable name="shippedAt" />
        <Searchable name="shippedAt" type="datetime" />
      </>
    ),
    thProps: { w: 40 },
  },
  {
    id: "shippingAlertOn",
    render: (shipment) => renderDate(shipment.shippingAlertOn),
    renderHeader: () => (
      <>
        <Sortable name="shippingAlertOn" />
        <Searchable name="shippingAlertOn" type="date" />
      </>
    ),
    thProps: { w: 52 },
  },
  {
    id: "deliveredAt",
    render: (shipment) => renderDateTime(shipment.deliveredAt),
    renderHeader: () => (
      <>
        <Sortable name="deliveredAt" />
        <Searchable name="deliveredAt" type="datetime" />
      </>
    ),
    thProps: { w: 40 },
  },
  {
    id: "deliveryAlertOn",
    render: (shipment) => renderDate(shipment.deliveryAlertOn),
    renderHeader: () => (
      <>
        <Sortable name="deliveryAlertOn" />
        <Searchable name="deliveryAlertOn" type="date" />
      </>
    ),
    thProps: { w: 60 },
  },
  {
    id: "createdAt",
    render: (shipment) => renderDateTime(shipment.createdAt),
    renderHeader: () => (
      <>
        <Sortable name="createdAt" />
        <Searchable name="createdAt" type="datetime" />
      </>
    ),
    thProps: { w: 40 },
  },
  ...generalNames.map(
    (name) =>
      ({
        id: name,
        render: (shipment) => <ReadMore text={shipment[name]} />,
        renderHeader: () => (
          <>
            <ColumnAliasable ml={2} name={name} />
            <Searchable ml={"auto"} name={name} type="input" />
          </>
        ),
        thProps: { w: 80 },
      } as Column)
  ),
];

export const ShipmentTable: React.VFC<Props & BoxProps> = ({ checked, setChecked, shipments }) => {
  const { columnOptions } = useShipmentTableColumns();

  const allChecked = !!shipments && shipments?.length > 0 && shipments?.length === checked.length;
  const isIndeterminate = checked.length > 0 && !allChecked;

  const columnLength = 17;
  const isBlank = shipments?.length === 0;

  const columns = React.useMemo(
    () =>
      columnOptions.filter((option) => option.enabled).map((option) => find(Columns, { id: option.id })) as Column[],
    [columnOptions]
  );

  return (
    <Box>
      <Card bodyProps={{ p: 0 }} height={isBlank ? "80" : "auto"} mt={4} pos="relative">
        <CheckboxGroup>
          <TableContainer
            className="scrollbar"
            maxH="80vh"
            overflowX={shipments?.length === 0 ? "hidden" : "scroll"}
            overflowY="scroll"
          >
            <Table className="table-sticky" size="sm" sx={{ tableLayout: "fixed" }}>
              <Thead>
                <Tr>
                  <Th w={12}>
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setChecked(shipments?.map((s) => s.id) || []);
                        } else {
                          setChecked([]);
                        }
                      }}
                    />
                  </Th>
                  <Th w={10}></Th>
                  <Th w={10}></Th>

                  {columns.map((column) => (
                    <CustomTh key={column.id} {...column.thProps}>
                      {column.renderHeader?.()}
                    </CustomTh>
                  ))}

                  <Th w={28}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {shipments?.map((shipment) => (
                  <Tr key={shipment.id}>
                    <Td>
                      <Checkbox
                        isChecked={checked.includes(shipment.id)}
                        onChange={(e) => {
                          setChecked((value) => {
                            if (e.target.checked) {
                              return [...value, shipment.id];
                            } else {
                              return value.filter((id) => id !== shipment.id);
                            }
                          });
                        }}
                      />
                    </Td>

                    <Td>
                      {shipment.shippingAlert && (
                        <Tooltip label="出荷確認ができません" placement="top">
                          <chakra.span color={"red"}>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </chakra.span>
                        </Tooltip>
                      )}
                    </Td>

                    <Td>
                      {shipment.deliveryAlert && (
                        <Tooltip label="配達完了確認ができません" placement="top">
                          <chakra.span color={"purple"}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                          </chakra.span>
                        </Tooltip>
                      )}
                    </Td>

                    {columns.map((column) => (
                      <Td key={column.id} {...column.tdProps}>
                        {column.render?.(shipment) || shipment[column.id]}
                      </Td>
                    ))}

                    <Td>
                      <Stack direction="row" spacing={2}>
                        <NextLink href={pagesPath.shipments._id(shipment.id).$url()} passHref>
                          <IconButton aria-label="" as="a" isRound>
                            <FontAwesomeIcon icon={faPen} />
                          </IconButton>
                        </NextLink>

                        <DeleteShipmentButton shipment={shipment} />
                      </Stack>
                    </Td>
                  </Tr>
                ))}

                {(shipments === null || shipments === undefined) &&
                  range(10).map((i) => (
                    <Tr key={i}>
                      <Td></Td>
                      {range(columnLength).map((i) => (
                        <Td key={i}>
                          <Skeleton height="32px" />
                        </Td>
                      ))}
                    </Tr>
                  ))}
              </Tbody>
            </Table>

            {isBlank && (
              <Center color="muted" fontSize="2xl" inset={0} mt="8" position="absolute" py={12}>
                データがありません
              </Center>
            )}
          </TableContainer>
        </CheckboxGroup>
      </Card>
    </Box>
  );
};
