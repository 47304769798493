import { find } from "lodash";
import { useTranslation } from "react-i18next";

import { ColumnOption } from "~/components/page/shipments/ShipmentTableContext";
import { useMe } from "~hooks/useMe";

export const generalNames = ["general1", "general2", "general3", "general4", "general5"] as const;
export type GeneralType = typeof generalNames[number];

export const useGeneralLabels = () => {
  const { t } = useTranslation();
  const { user } = useMe();

  const generalLabels = generalNames.reduce((obj, name) => {
    const option = find<ColumnOption>(user?.group.columnOptions, { id: name });
    obj[name] = option?.alias || t(`model.shipment.${name}`);
    return obj;
  }, {} as { [k in GeneralType]: string });

  return { generalLabels };
};
