import { Box, BoxProps, Menu, MenuButton, MenuList, Skeleton, Flex } from "@chakra-ui/react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { castArray, mapValues, pick } from "lodash";
import { useRouter } from "next/router";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { Pagination } from "~/components/navigation/Pagination";
import { ReloadButton } from "~/components/page/shipments/ReloadButton";
import { SearchConditions } from "~/components/page/shipments/SearchConditions";
import { ShipmentAlerts } from "~/components/page/shipments/ShipmentAlerts";
import { ShipmentTable } from "~/components/page/shipments/ShipmentTable";
import { ShipmentTableContext } from "~/components/page/shipments/ShipmentTableContext";
import { ShipmentTableSetting } from "~/components/page/shipments/ShipmentTableSetting";
import { DeleteMenuItem } from "~/components/page/shipments/menu-item/DeleteMenuItem";
import { FetchTrackingsMenuItem } from "~/components/page/shipments/menu-item/FetchTrackingsMenuItem";
import { useShipmentsQuery } from "~generated/graphql";
import { Overlay } from "~overlay/Overlay";

type Props = {};

export const queryNames = [
  "id",
  "type",
  "trackingNumber",
  "status",
  "latestTrackingStatus",
  "shippedAt",
  "shippingAlertOn",
  "shippingAlert",
  "deliveredAt",
  "deliveryAlertOn",
  "deliveryAlert",
  "createdAt",
  "general1",
  "general2",
  "general3",
  "general4",
  "general5",
];

export const Shipments: React.VFC<Props & BoxProps> = ({ ...props }) => {
  const router = useRouter();
  const page = parseInt(String(router.query.page || "1"));
  const per = parseInt(String(router.query.per || "50"));
  const order = router.query.order && String(router.query.order);

  const { data, loading, previousData } = useShipmentsQuery({
    variables: {
      queries: {
        ...pick(mapValues(router.query, castArray), queryNames),
        deliveryAlert: router.query.deliveryAlert === "1",
        order,
        page,
        per,
        shippingAlert: router.query.shippingAlert === "1",
      },
    },
  });

  const [checked, setChecked] = React.useState<number[]>([]);

  const scrollLeft = React.useRef(0);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const onPageChange = (page: number, per: number) => {
    router.push(
      {
        query: { ...router.query, page, per },
      },
      undefined,
      { shallow: true }
    );
  };

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, containerRef.current]);

  const resetChecked = () => setChecked([]);

  const shipments = data?.shipments?.shipments || previousData?.shipments?.shipments;
  const pageInfo = data?.shipments?.pageInfo || previousData?.shipments?.pageInfo;

  return (
    <Box {...props}>
      <ShipmentAlerts />

      <SearchConditions mt={4} />

      <ShipmentTableContext>
        <Overlay isLoading={loading}>
          <Flex display="flex" flexWrap="wrap" gap="4" mt="4">
            <Box flexGrow="1">
              {shipments && (
                <Menu>
                  <MenuButton
                    as={ChakraButton}
                    disabled={checked.length === 0}
                    flexShrink={0}
                    rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
                  >
                    まとめて操作
                  </MenuButton>

                  <MenuList zIndex="docked">
                    <FetchTrackingsMenuItem ids={checked} resetChecked={resetChecked} />
                    <DeleteMenuItem ids={checked} resetChecked={resetChecked} />
                  </MenuList>
                </Menu>
              )}
              {!shipments && <Skeleton height="44px" w="32" />}
            </Box>

            {shipments && (
              <>
                <Pagination onChange={onPageChange} page={page} pageInfo={pageInfo} per={per} />
                <ReloadButton />
                <ShipmentTableSetting />
              </>
            )}
            {!shipments && <Skeleton height="44px" width="200px" />}
          </Flex>

          <ShipmentTable checked={checked} setChecked={setChecked} shipments={shipments} />
          <Pagination mt={6} onChange={onPageChange} page={page} pageInfo={pageInfo} per={per} />
        </Overlay>
      </ShipmentTableContext>
    </Box>
  );
};
