import { MenuItemProps, useToast } from "@chakra-ui/react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import { BaseMenuItem } from "~/components/page/shipments/menu-item/BaseMenuItem";
import { useFetchTrackingsCsvMutation } from "~generated/graphql";

type Props = {
  ids: number[];
  resetChecked: () => void;
};

export const FetchTrackingsMenuItem: React.VFC<Props & MenuItemProps> = ({ ids, ...props }) => {
  const [mutation] = useFetchTrackingsCsvMutation();
  const toast = useToast();

  const onMenuClick = async () => {
    await mutation({ variables: { input: { ids } } });
    toast({ position: "top", status: "success", title: "取得しました" });
  };

  return (
    <BaseMenuItem menuIcon={faSync} onMenuClick={onMenuClick} {...props}>
      配送ステータスを取得
    </BaseMenuItem>
  );
};
