import { Alert, BoxProps, chakra } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { useAlertCountsQuery } from "~generated/graphql";

type Props = {};

export const ShipmentAlerts: React.VFC<Props & BoxProps> = () => {
  const router = useRouter();
  const { data } = useAlertCountsQuery();

  const counts = data?.alertCounts;

  if (!counts) return null;

  const onClick = (query: any) => {
    router.push(
      {
        query: { page: 1, ...query },
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <>
      {counts.error > 0 && (
        <Alert alignItems="center" display="flex" mt={4} status="error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <chakra.span fontWeight="bold" px={2}>
            エラーの配送データが
            <chakra.span fontSize="lg" px={1}>
              {counts.error}件
            </chakra.span>
            あります
          </chakra.span>
          <ChakraButton colorScheme="brand" flexShrink={0} ml="auto" onClick={() => onClick({ status: ["error"] })}>
            データを見る
          </ChakraButton>
        </Alert>
      )}

      {counts.shipping > 0 && (
        <Alert alignItems="center" display="flex" mt={4} status="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <chakra.span fontWeight="bold" px={2}>
            出荷確認ができないデータが
            <chakra.span fontSize="lg" px={1}>
              {counts.shipping}件
            </chakra.span>
            あります
          </chakra.span>
          <ChakraButton colorScheme="brand" flexShrink={0} ml="auto" onClick={() => onClick({ shippingAlert: 1 })}>
            データを見る
          </ChakraButton>
        </Alert>
      )}

      {counts.delivery > 0 && (
        <Alert alignItems="center" display="flex" mt={4} status="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <chakra.span fontWeight="bold" px={2}>
            配達完了確認ができないデータが
            <chakra.span fontSize="lg" px={1}>
              {counts.delivery}件
            </chakra.span>
            あります
          </chakra.span>
          <ChakraButton colorScheme="brand" flexShrink={0} ml="auto" onClick={() => onClick({ deliveryAlert: 1 })}>
            データを見る
          </ChakraButton>
        </Alert>
      )}
    </>
  );
};
