import { MenuItem } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSetRecoilState } from "recoil";

import { useConfirm } from "~hooks/useConfirm";
import { useRefetchShipments } from "~hooks/useRefetchShipments";
import { loadingState } from "~recoil/atoms/loadingState";

type Props = {
  confirmMessage?: string;
  menuIcon: IconDefinition;
  onMenuClick: () => Promise<void>;
  resetChecked: () => void;
};

export const BaseMenuItem: React.FC<Props> = ({
  confirmMessage,
  menuIcon,
  children,
  onMenuClick,
  resetChecked,
  ...props
}) => {
  const { refetchAll } = useRefetchShipments();
  const setLoading = useSetRecoilState(loadingState);
  const confirm = useConfirm();

  const onClick = async () => {
    if (confirmMessage && !(await confirm(confirmMessage))) return;

    setLoading({ loading: true });
    await onMenuClick();
    await refetchAll();
    resetChecked();
    setLoading({ loading: false });
  };

  return (
    <MenuItem icon={<FontAwesomeIcon icon={menuIcon} />} onClick={onClick} {...props}>
      {children}
    </MenuItem>
  );
};
