import { BoxProps, chakra, Flex, Tooltip } from "@chakra-ui/react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string;
  name: string;
};

export const Sortable: React.VFC<Props & BoxProps> = ({ name, label, ...props }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const order = router.query.order;

  const onClick = () => {
    router.push(
      {
        query: {
          ...router.query,
          order: order === name ? `-${name}` : name,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <Flex alignItems="center" cursor="pointer" onClick={onClick} {...props}>
      <Tooltip label="ソート" placement="top">
        <chakra.span _hover={{ textDecoration: "underline" }} color="blue" pr={2}>
          {label || t(`model.shipment.${name}` as any)}
        </chakra.span>
      </Tooltip>
      {order === name && <FontAwesomeIcon icon={faCaretUp} />}
      {order === `-${name}` && <FontAwesomeIcon icon={faCaretDown} />}
    </Flex>
  );
};
