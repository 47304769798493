import { IconButton } from "@chakra-ui/button";
import { BoxProps, Center } from "@chakra-ui/layout";
import { Box, Select } from "@chakra-ui/react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { PageInfo } from "~generated/graphql";

type Props = {
  onChange?: (page: number, per: number) => void;
  page: number;
  pageInfo: PageInfo | undefined;
  per: number;
};

export const Pagination: React.VFC<Props & Omit<BoxProps, "onChange">> = ({
  onChange,
  page = 1,
  pageInfo,
  per = 100,
  ...props
}) => {
  if (!pageInfo || pageInfo.totalCount === 0) return null;

  return (
    <Center justifyContent="flex-start" {...props}>
      <IconButton
        aria-label=""
        isDisabled={pageInfo.currentPage === 1}
        isRound
        onClick={() => onChange?.(pageInfo.prevPage || pageInfo.totalPages, per)}
        w={12}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </IconButton>

      <IconButton
        aria-label=""
        isDisabled={pageInfo.nextPage === null}
        isRound
        ml={2}
        onClick={() => pageInfo.nextPage && onChange?.(pageInfo.nextPage, per)}
        w={12}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </IconButton>

      <Box fontSize="sm" ml={4}>
        {page}ページ目 全 {pageInfo.totalCount} 件
      </Box>

      <Box ml={4}>
        <Select onChange={(event) => onChange?.(1, Number(event.target.value))} value={per}>
          <option value="50">50件</option>
          <option value="100">100件</option>
          <option value="200">200件</option>
        </Select>
      </Box>
    </Center>
  );
};
