import { Box, BoxProps, Center } from "@chakra-ui/react";
import React from "react";

import { Spinner } from "~/components/feedback/Spinner";

type Props = {
  isLoading: boolean;
  noSpinner?: boolean;
};

export const Overlay: React.FC<Props & BoxProps> = ({ children, isLoading, noSpinner = false, ...props }) => {
  return (
    <Box pos="relative" {...props}>
      {isLoading && (
        <Center
          backdropFilter="blur(10px)"
          bottom={0}
          left={0}
          opacity={0.6}
          pos="absolute"
          right={0}
          top={0}
          zIndex="docked"
        >
          {!noSpinner && <Spinner />}
        </Center>
      )}
      {children}
    </Box>
  );
};
