import { IconButton, IconButtonProps, useToast } from "@chakra-ui/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Shipment, useDestroyShipmentMutation } from "~generated/graphql";
import { useConfirm } from "~hooks/useConfirm";
import { useRefetchShipments } from "~hooks/useRefetchShipments";

type Props = {
  shipment: Shipment;
};

export const DeleteShipmentButton: React.VFC<Props & Omit<IconButtonProps, "aria-label">> = ({
  shipment,
  ...props
}) => {
  const [mutation, { loading }] = useDestroyShipmentMutation();
  const toast = useToast();
  const confirm = useConfirm();
  const { refetchAll } = useRefetchShipments();

  const onDestroyClick = async (ids: number[]) => {
    if (!(await confirm("本当に削除してもよろしいですか"))) return;

    await mutation({ variables: { input: { ids } } });
    await refetchAll();
    toast({ position: "top", status: "success", title: "削除しました" });
  };

  return (
    <IconButton
      aria-label=""
      colorScheme="red"
      isLoading={loading}
      isRound
      onClick={() => onDestroyClick([shipment.id])}
      {...props}
    >
      <FontAwesomeIcon icon={faTrash} />
    </IconButton>
  );
};
