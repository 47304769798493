import { chakra, Link, LinkProps } from "@chakra-ui/react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { ShipmentTypeEnum } from "~generated/graphql";

type Props = {
  trackingNumber: string;
  type: ShipmentTypeEnum;
};

const urls = {
  Hukuyama: "",
  Sagawa: "http://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=",
  Seino: "",
  Yamato: "http://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=",
  YouPack: "http://tracking.post.japanpost.jp/service/singleSearch.do?searchKind=S003&locale=ja&SVID=023&reqCodeNo1=",
};

export const TrackingNumber: React.VFC<Props & LinkProps> = ({ trackingNumber, type, ...props }) => {
  return (
    <Link color="blue" href={urls[type] + trackingNumber} isExternal {...props}>
      <chakra.span pr={2}>
        {trackingNumber.slice(0, 4)}-{trackingNumber.slice(4, 8)}-{trackingNumber.slice(8, 12)}
      </chakra.span>
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </Link>
  );
};
