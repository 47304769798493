import { BoxProps, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { faCaretDown, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import FileSaver from "file-saver";
import { castArray, mapValues, pick } from "lodash";
import { useRouter } from "next/router";
import React from "react";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { queryNames } from "~/components/page/shipments/Shipments";
import { useGetShipmentCsvMutation } from "~generated/graphql";
import { useRefetchShipments } from "~hooks/useRefetchShipments";
import { Overlay } from "~overlay/Overlay";

type Props = {};

export const CsvDownloadMenu: React.VFC<Props & BoxProps> = ({ ...props }) => {
  const router = useRouter();
  const order = router.query.order && String(router.query.order);

  const [mutation, { loading }] = useGetShipmentCsvMutation();
  const { refetchShipments } = useRefetchShipments();

  const onCsvDownloadClick = async ({ withQuery = false } = {}) => {
    let queries: any = {};
    if (withQuery) {
      queries = {
        ...pick(mapValues(router.query, castArray), queryNames),
        order,
      };

      if (router.query.notShippedAlert) queries.notShippedAlert = 1;
    }

    const { data } = await mutation({ variables: { input: { queries } } });
    const csv = data?.getShipmentCsv?.csv;

    if (!csv) throw new Error();

    FileSaver.saveAs(
      new Blob([csv], { type: "text/plain:charset=utf-8" }),
      `配送ステータス_${format(new Date(), "yyyy_MM_dd_HH_mm_SS")}.csv`
    );
    refetchShipments();
  };

  return (
    <Overlay isLoading={loading} {...props}>
      <Menu>
        <MenuButton as={ChakraButton} rightIcon={<FontAwesomeIcon icon={faCaretDown} />}>
          CSVダウンロード
        </MenuButton>
        <MenuList zIndex="docked">
          <MenuItem icon={<FontAwesomeIcon icon={faDownload} />} onClick={() => onCsvDownloadClick()}>
            すべて
          </MenuItem>
          <MenuItem
            icon={<FontAwesomeIcon icon={faDownload} />}
            onClick={() => onCsvDownloadClick({ withQuery: true })}
          >
            検索条件にマッチしたもののみ
          </MenuItem>
        </MenuList>
      </Menu>
    </Overlay>
  );
};
