import { Box, BoxProps } from "@chakra-ui/react";
import escape from "escape-html";
import React from "react";
import nl2br from "react-nl2br";
import ShowMoreText from "react-show-more-text";

type Props = {
  text: string | null | undefined;
};

export const ReadMore: React.FC<Props & BoxProps> = ({ text, ...props }) => {
  return (
    <Box {...props}>
      <ShowMoreText anchorClass="show-more-anchor" less="閉じる" lines={3} more="もっと見る">
        <Box whiteSpace="initial" wordBreak={"break-all"}>
          {text ? nl2br(escape(text)) : null}
        </Box>
      </ShowMoreText>
    </Box>
  );
};
