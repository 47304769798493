import { Center, Spinner as BaseSpinner, SpinnerProps } from "@chakra-ui/react";
import React from "react";

type Props = {
  centered?: boolean;
};

export const Spinner: React.VFC<Props & SpinnerProps> = ({ centered = false, size = "xl", ...props }) => {
  return (
    <Center bottom={0} left={0} pos={centered ? "fixed" : "static"} right={0} top={0} {...props}>
      <BaseSpinner color="accent.400" size={size} thickness="2px" />
    </Center>
  );
};
