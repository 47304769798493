import { Box, BoxProps } from "@chakra-ui/react";
import { find } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { ShipmentStatusEnum } from "~generated/graphql";

type Props = {
  shipmentStatus: ShipmentStatusEnum | undefined;
};

const colors = {
  delivered: "green",
  error: "error",
  initial: "gray",
  not_registered: "orange",
  researching: "purple",
  shipped: "cyan.600",
  undefined: "black",
};

export const ShipmentStatus: React.VFC<Props & BoxProps> = ({ shipmentStatus, ...props }) => {
  const { t } = useTranslation();

  if (!shipmentStatus) return null;

  const option = find(t("model.shipment.statusOptions", { returnObjects: true }), { value: shipmentStatus });

  return (
    <Box
      bg={colors[shipmentStatus]}
      boxShadow="inset"
      color="white"
      fontWeight="bold"
      p={2}
      rounded="xl"
      textAlign="center"
      w={32}
      {...props}
    >
      {option?.label}
    </Box>
  );
};
