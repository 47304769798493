import { Box, BoxProps, chakra, Flex, Tooltip } from "@chakra-ui/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { last } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { queryNames } from "~/components/page/shipments/Shipments";

type Props = {
  name: string;
  onClick: (name: string, value: string) => void;
  value: string;
};

export const SearchCondition: React.VFC<Props & Omit<BoxProps, "onClick">> = ({ onClick, name, value, ...props }) => {
  const { t } = useTranslation();

  if (!queryNames.includes(name)) return null;

  const getOptionLabel = () => {
    return t(`model.shipment.${name}Options` as any, {
      returnObjects: true,
    }).find((option: any) => option.value === last(value.split("-")))?.label;
  };

  let valueText = null;

  if (name.endsWith("At") || name.endsWith("On")) {
    if (value === "null") {
      valueText = "データなし";
    } else if (value === "notNull") {
      valueText = "データあり";
    } else {
      const formatStr = name.endsWith("At") ? "yyyy/MM/dd HH:mm" : "yyyy/MM/dd";
      valueText = value
        .split("_")
        .map((v) => (v ? format(new Date(v), formatStr) : null))
        .join(" 〜 ");
    }
  } else if (["status", "type"].includes(name)) {
    valueText = getOptionLabel();
  } else if (name === "shippingAlert" || name === "deliveryAlert") {
    valueText = "";
  } else {
    valueText = value;
  }

  if (valueText == null) return null;

  const exclude = value.startsWith("-");

  const label = t(`model.shipment.${name}` as any);

  return (
    <Box {...props}>
      <Tooltip label="クリックで削除">
        <ChakraButton
          colorScheme={exclude ? "blue" : "brand"}
          key={value}
          onClick={() => onClick(name, value)}
          rightIcon={<FontAwesomeIcon icon={faTimes} />}
        >
          <Flex alignItems="baseline">
            <chakra.span color={"gray.200"} fontSize="xs" fontWeight="bold" pr={4}>
              {label}
            </chakra.span>
            {valueText}
            {exclude && (
              <chakra.span fontSize={"xs"} ml={1}>
                (除外)
              </chakra.span>
            )}
          </Flex>
        </ChakraButton>
      </Tooltip>
    </Box>
  );
};
