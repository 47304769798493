import {
  Box,
  BoxProps,
  Tooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { find } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { useShipmentTableColumns } from "~/components/page/shipments/ShipmentTableContext";
import { useUpdateColumnOptionsMutation } from "~generated/graphql";
import { useGeneralLabels } from "~hooks/useGeneralLabels";
import { useHandleInvalid } from "~hooks/useHandleInvalid";
import { useMe } from "~hooks/useMe";
import yup from "~libs/yup";

type Props = {
  name: "general1" | "general2" | "general3" | "general4" | "general5";
};

type Inputs = {
  alias: string;
};

const MAX_LENGTH = 10;

const schema = yup.object().shape({
  alias: yup.string().max(MAX_LENGTH),
});

export const ColumnAliasable: React.VFC<Props & BoxProps> = ({ name, ...props }) => {
  const { t } = useTranslation();
  const { columnOptions } = useShipmentTableColumns();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mutation, { loading }] = useUpdateColumnOptionsMutation();
  const toast = useToast();
  const { generalLabels } = useGeneralLabels();
  const { user } = useMe();

  const initialRef = React.useRef<HTMLInputElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(schema, { stripUnknown: true }),
  });
  const handleInvalid = useHandleInvalid();

  const onClick = () => {
    const column = find(columnOptions, { id: name });
    setValue("alias", column?.alias || "");
    onOpen();
  };

  const onSubmit = async ({ alias }: Inputs) => {
    if (!user) return;

    const value = columnOptions.map((option) => (option.id === name ? { ...option, alias } : option));

    await mutation({ variables: { input: { columnOptions: value } } });
    toast({ position: "top", title: "保存しました" });

    setTimeout(onClose); // loadingが戻らない場合があるので
  };

  const label = generalLabels[name] || t(`model.shipment.${name}`);
  const { ref, ...rest } = register("alias");

  return (
    <Box alignItems="center" display={"flex"} {...props}>
      <Box>{label}</Box>
      <Tooltip label="カラム名変更" placement="top">
        <ChakraButton colorScheme="blue" onClick={onClick} size="xs" variant={"ghost"}>
          <FontAwesomeIcon icon={faPen} />
        </ChakraButton>
      </Tooltip>

      <Modal
        initialFocusRef={initialRef}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit, handleInvalid)}>
            <ModalHeader>カラム名変更</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={!!errors.alias}>
                <FormLabel>カラム名</FormLabel>
                <Input
                  {...rest}
                  placeholder={t(`model.shipment.${name}`)}
                  ref={(e) => {
                    ref(e);
                    initialRef.current = e;
                  }}
                />
                <FormHelperText>{MAX_LENGTH}文字以内</FormHelperText>
                <FormErrorMessage>{errors.alias?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <ChakraButton colorScheme={"brand"} isLoading={loading} type="submit">
                変更する
              </ChakraButton>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
