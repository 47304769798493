import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faBars, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIndex } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChakraButton } from "~/components/alias/ChakraButton";
import { ColumnOption, useShipmentTableColumns } from "~/components/page/shipments/ShipmentTableContext";
import { ShipmentFragment, useUpdateColumnOptionsMutation } from "~generated/graphql";
import { GeneralType, useGeneralLabels } from "~hooks/useGeneralLabels";

type Props = {};

const SortableItem = ({
  columnOption,
  setEnabled,
}: {
  columnOption: ColumnOption;
  setEnabled: (id: keyof ShipmentFragment, enabled: boolean) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: columnOption.id });
  const { t } = useTranslation();
  const { generalLabels } = useGeneralLabels();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const label = generalLabels[columnOption.id as GeneralType] || t(`model.shipment.${columnOption.id}` as any);

  return (
    <Flex
      alignItems="center"
      bg="white"
      borderWidth={1}
      boxShadow={isDragging ? "2xl" : "none"}
      gap={4}
      key={columnOption.id}
      mt="-1px"
      pos="relative"
      px={4}
      py={2}
      ref={setNodeRef}
      style={style}
      zIndex={isDragging ? "overlay" : "auto"}
    >
      <Box color="gray.300" {...attributes} {...listeners} cursor={"grab"}>
        <FontAwesomeIcon icon={faBars} />
      </Box>
      <Checkbox isChecked={columnOption.enabled} onChange={(e) => setEnabled(columnOption.id, e.target.checked)}>
        {label}
      </Checkbox>
    </Flex>
  );
};

export const ShipmentTableSetting: React.VFC<Props & BoxProps> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { columnOptions, setColumnOptions } = useShipmentTableColumns();
  const [mutation, { loading }] = useUpdateColumnOptionsMutation();
  const toast = useToast();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onClick = () => {
    onOpen();
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setColumnOptions((columns) => {
        const oldIndex = findIndex(columns, { id: active.id as keyof ShipmentFragment });
        const newIndex = findIndex(columns, { id: over?.id as keyof ShipmentFragment });

        return arrayMove(columns, oldIndex, newIndex);
      });
    }
  };

  const setEnabled = (id: keyof ShipmentFragment, enabled: boolean) => {
    setColumnOptions((columnOptions) => {
      return columnOptions.map((option) => (option.id === id ? { ...option, enabled } : option));
    });
  };

  const onSubmit = async () => {
    await mutation({ variables: { input: { columnOptions } } });
    toast({ position: "top", title: "保存しました" });
    onClose();
  };

  return (
    <Box {...props}>
      <Tooltip label="設定">
        <IconButton aria-label="" onClick={onClick}>
          <FontAwesomeIcon icon={faCog} />
        </IconButton>
      </Tooltip>

      <Modal isCentered isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} scrollBehavior="inside" size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>設定</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box>
              <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
                <SortableContext items={columnOptions} strategy={verticalListSortingStrategy}>
                  {columnOptions.map((columnOption) => (
                    <SortableItem columnOption={columnOption} key={columnOption.id} setEnabled={setEnabled} />
                  ))}
                </SortableContext>
              </DndContext>
            </Box>
            <Box color={"muted"} fontSize="sm" mt={1}>
              チェックで表示
            </Box>
          </ModalBody>

          <ModalFooter gap={4}>
            <ChakraButton colorScheme={"brand"} isLoading={loading} onClick={onSubmit} w={32}>
              OK
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
